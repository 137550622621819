import React, { useState, useContext } from "react";
import { SignUpForm } from "../SignUpForm";
import { Heading } from "../Library/Heading";
import { CenteredLayout } from "../Library/Layout/CenteredLayout";
import { LogInScreen } from "../LogInScreen/LogInScreen";

export function SignUpView() {
	return (
		<>
			<Heading className="log-in" content={"Log In"} />
			<CenteredLayout>
				<LogInScreen />
			</CenteredLayout>
			<Heading className="sign-up" content={"Sign Up"} />
			<CenteredLayout>
				<SignUpForm />
			</CenteredLayout>
		</>
	);
}
