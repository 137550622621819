import { useState, useRef, useEffect, ChangeEvent } from "react";

export function useField(
	name: string,
	form: any,
	{
		defaultValue,
		validations,
		fieldsToValidateOnChange,
	}: {
		defaultValue: string | undefined;
		validations: Validator[];
		fieldsToValidateOnChange: string[];
	}
) {
	let [value, setValue] = useState(defaultValue);
	let [errors, setErrors] = useState<string[]>([]);
	let [pristine, setPristine] = useState(true);
	let [validating, setValidating] = useState(false);
	let validateCounter = useRef(0);
	const validate = async () => {
		let validateIteration = ++validateCounter.current;
		setValidating(true);
		let formData = form.getFormData();
		let errorMessages = await Promise.all(validations.map((validation) => validation(formData, name)));
		errorMessages = errorMessages.filter((errorMsg) => !!errorMsg);
		if (validateIteration === validateCounter.current) {
			// this is the most recent invocation
			setErrors(errorMessages as string[]);
			setValidating(false);
		}
		let fieldValid = errorMessages.length === 0;
		return fieldValid;
	};
	useEffect(() => {
		if (pristine) return; // Avoid validate on mount
		form.validateFields(fieldsToValidateOnChange);
	}, [value]);
	let field = {
		name,
		value,
		errors,
		setErrors,
		pristine,
		onChange: (e: ChangeEvent<HTMLFormElement>) => {
			if (pristine) {
				setPristine(false);
			}
			setValue(e.target.value);
		},
		validate,
		validating,
	} as FieldDef;
	form.addField(field);
	return field;
}

export type FieldDef = {
	name: string;
	value: string | undefined;
	errors: string[];
	setErrors: (errors: string[]) => void;
	pristine: boolean;
	onChange: (e: ChangeEvent<HTMLFormElement>) => void;
	validate: () => Promise<boolean>;
	validating: boolean;
};

export type Validator = (formData: any, input: string) => string | false;
