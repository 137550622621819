import React, { useContext, useState } from "react";
import { SignUpAPI } from "../API/SignUpAPI";
import "./SignUpForm.scss";
import { ViewContext } from "../Context/ViewContext";
import { VerificationEmailView } from "./Views/VerificationEmailView";
import * as EmailValidator from "email-validator";
import { useField } from "../Hooks/Forms/useField";
import { useForm } from "../Hooks/Forms/useForm";
import { Field } from "./Field";
import { validatePassword } from "./validatePassword";

export function SignUpForm() {
	const [signingUp, setSigningUp] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

	const form = useForm({
		onSubmit: async (formData, valid) => {
			if (!valid) return;

			setSigningUp(true);
			setErrorMessage(undefined);

			try {
				await SignUpAPI.SignUp(formData.emailAddress, formData.password, formData.firstName);
				setCurrentView(VerificationEmailView);
			} catch (err) {
				setErrorMessage(err.toString());
				setSigningUp(false);
				console.error(err);
			}
		},
	});

	const firstNameField = useField("firstName", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.firstName.length === 0 && "Firstname missing!!";
			},
		],
		fieldsToValidateOnChange: [],
	});

	const emailAddressField = useField("emailAddress", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.emailAddress.length === 0 && "Email address missing!!";
			},
			(formData) =>
				formData.emailAddress.length > 0 && !EmailValidator.validate(formData.emailAddress) && "Email address invalid",
		],
		fieldsToValidateOnChange: [],
	});

	const passwordField = useField("password", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.password.length === 0 && "Password missing!!";
			},
			(formData) => formData.password.length > 0 && !validatePassword(formData.password) && "Password insufficient",
		],
		fieldsToValidateOnChange: [],
	});

	const { setCurrentView } = useContext(ViewContext);

	return (
		<>
			<form className="signup" onSubmit={form.onSubmit}>
				<fieldset>
					<Field {...firstNameField} formSubmitted={form.submitted} label="First Name" disabled={signingUp} />
					<Field
						{...emailAddressField}
						formSubmitted={form.submitted}
						label="Email Address"
						type="email"
						disabled={signingUp}
					/>
					<Field
						{...passwordField}
						formSubmitted={form.submitted}
						label="Password"
						type="password"
						disabled={signingUp}
					/>

					<input type="submit" value="Sign Up!" disabled={signingUp} />
				</fieldset>
			</form>
			<div className="error">{errorMessage}</div>
		</>
	);
}
