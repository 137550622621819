import React from "react";

export function Field({
	label,
	name,
	value,
	onChange,
	errors,
	setErrors,
	pristine,
	validating,
	validate,
	formSubmitted,
	...other
}: any) {
	let showErrors = (!pristine || formSubmitted) && !!errors.length;
	return (
		<div className="field">
			<label htmlFor={name}>{label}</label>
			<input id={name} value={value} onChange={onChange} onBlur={() => !pristine && validate()} {...other} />
			<div>{showErrors && errors.map((errorMsg: any) => <div key={errorMsg}>{errorMsg}</div>)}</div>
		</div>
	);
}
