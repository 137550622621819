import React, { useState, useContext, useEffect } from "react";
import { AccountScreenProps } from "./AccountScreen.Interface";
import "./AccountScreen.scss";
import { auth } from "../../AuthManager/AuthManager";
import { JwtModel } from "../../AuthManager/JwtModel";
import { IAvailableIntegrationResponseModel } from "../../API/Models/IAvailableIntegrationResponseModel";
import { AvailableIntegrationsAPI } from "../../API/AvailableIntegrationsAPI";

export function AccountScreen(props: AccountScreenProps) {
	const [jwtData, setJwtData] = useState<JwtModel | null>(auth.GetJwtData());

	const [integrations, setIntegrations] = useState<IAvailableIntegrationResponseModel[]>([]);

	useEffect(() => {
		AvailableIntegrationsAPI.GetAvailableIntegrations().then((int) => {
			setIntegrations(int.availableIntegrations);
		});
	}, []);

	const integrationLinks = integrations.map((ai) => (
		<div className="integration-item">
			<h3 className="integration-name">{ai.displayName}</h3>
			<a className="new-integration-link" href={`${ai.authUrl}`}>
				LINK TO {ai.displayName}
			</a>
		</div>
	));

	const [epUser, setEpUser] = useState("");
	const [epPass, setEpPass] = useState("");
	const [working, setWorking] = useState(false);

	async function linkToEp() {
		if (!epUser) return;

		setWorking(true);

		try {
			const resp = await fetch("/api/ep", {
				method: "POST",
				body: JSON.stringify({ username: epUser, password: epPass }),
				headers: {
					Authorization: `Bearer ${auth.Token}`,
					"Content-Type": "application/json",
				},
			});
			const text = await resp.text();
			alert(text);
		} catch (err) {
			alert(err);
		} finally {
			setWorking(false);
		}
	}

	return (
		<section className="account-screen">
			<section className="account-details">
				<div className="header">Account Details</div>
				<div>aud: {jwtData ? jwtData.aud : ""}</div>
				<div>org: {jwtData ? jwtData.org : ""}</div>
				<div>iss: {jwtData ? jwtData.iss : ""}</div>
			</section>
			<section className="integrations">
				<div className="header">Integrations</div>

				{/* TODO: Display current integrations, 
				only show integration button if that integration doesn't exist, 
				otherwise it will be a 'remove integration' button */}
				{integrationLinks}
				<div className="integration-item">
					<h3 className="integration-name">Easy Projects</h3>
					<form>
						<fieldset>
							<label htmlFor="epUser">EP Username:</label>
							<input
								id="epUser"
								value={epUser}
								onChange={(e) => setEpUser((e.target as HTMLInputElement).value)}
								disabled={working}
							/>

							<label htmlFor="epPass">EP Password:</label>
							<input
								id="epPass"
								value={epPass}
								type="password"
								onChange={(e) => setEpPass((e.target as HTMLInputElement).value)}
								disabled={working}
							/>
						</fieldset>
						<button
							type="button"
							className="new-integration-link"
							onClick={linkToEp}
							disabled={epUser === "" || working}
						>
							LINK TO EP
						</button>
					</form>
				</div>
			</section>
		</section>
	);
}
