import { useState, FormEvent } from "react";
import { Dictionary } from "../../../types/Dictionary";
import { FieldDef } from "./useField";

export function useForm({ onSubmit }: { onSubmit(formData: Dictionary<string>, valid: boolean): any }) {
	let [submitted, setSubmitted] = useState(false);
	let [submitting, setSubmitting] = useState(false);
	let fields: FieldDef[] = [];
	const validateFields = async (fieldNames?: string[]) => {
		let fieldsToValidate;
		if (fieldNames instanceof Array) {
			fieldsToValidate = fields.filter((field) => fieldNames.includes(field.name));
		} else {
			//if fieldNames not provided, validate all fields
			fieldsToValidate = fields;
		}
		let fieldsValid = await Promise.all(fieldsToValidate.map((field) => field.validate()));
		let formValid = fieldsValid.every((isValid) => isValid === true);
		return formValid;
	};
	const getFormData = () => {
		return fields.reduce(
			(formData, f) => {
				formData[f.name] = f.value || "";
				return formData;
			},
			{} as Dictionary<string>
		);
	};
	return {
		onSubmit: async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			setSubmitting(true);
			setSubmitted(true); // User has attempted to submit form at least once
			let formValid = await validateFields();
			let returnVal = await onSubmit(getFormData(), formValid);
			setSubmitting(false);
			return returnVal;
		},
		isValid: () => fields.every((f) => f.errors.length === 0),
		addField: (field: FieldDef) => fields.push(field),
		getFormData,
		validateFields,
		submitted,
		submitting,
	} as FormDef;
}

export type FormDef = {
	onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
	isValid: () => boolean;
	addField: (field: FieldDef) => number;
	getFormData: () => any;
	validateFields: (fields: string[]) => Promise<boolean>;
	submitted: boolean;
	submitting: boolean;
};
