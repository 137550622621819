import React, { useState, useContext, useEffect } from "react";
import { Heading } from "../Library/Heading";
import { CenteredLayout } from "../Library/Layout/CenteredLayout";
import { auth } from "../../AuthManager/AuthManager";
import { JwtModel } from "../../AuthManager/JwtModel";
import { AccountScreen } from "../AccountScreen/AccountScreen";

export function JiraView() {
	return (
		<>
			<Heading content={"Welcome, {firstname}!"} />
			<CenteredLayout>
				<AccountScreen />
			</CenteredLayout>
		</>
	);
}
