import React from "react";
import "./Heading.scss";

export enum HeadingSizeEnum {
	h1,
	h2,
	h3,
}

export function Heading(props: { content: string; size?: HeadingSizeEnum; className?: string }) {
	switch (props.size) {
		default:
		case HeadingSizeEnum.h1:
			return <h1 className={props.className}>{props.content}</h1>;
		case HeadingSizeEnum.h2:
			return <h2 className={props.className}>{props.content}</h2>;
		case HeadingSizeEnum.h3:
			return <h3 className={props.className}>{props.content}</h3>;
	}
}
