import React, { useState, useContext } from "react";
import { LogInScreenProps } from "./LogInScreen.Interface";
import "./LogInScreen.scss";
import { auth } from "../../AuthManager/AuthManager";
import { LoginResultEnum } from "../../AuthManager/LoginResultEnum";
import { ViewContext } from "../../Context/ViewContext";
import { JiraView } from "../Views/JiraView";
import { Heading } from "../Library/Heading";
import { useForm } from "../../Hooks/Forms/useForm";
import { SignUpAPI } from "../../API/SignUpAPI";
import { useField } from "../../Hooks/Forms/useField";
import { validatePassword } from "../validatePassword";
import { Field } from "../Field";

export function LogInScreen(props: LogInScreenProps) {
	// const [emailAddress, setEmailAddress] = useState<string>('')
	// const [password, setPassword] = useState<string>('')
	const { setCurrentView } = useContext(ViewContext);
	const [loggingIn, setLoggingIn] = useState(false);
	const [failed, setFailed] = useState<boolean>(false);

	const form = useForm({
		onSubmit: async (formData, valid) => {
			if (!valid) return;

			handleLogInAttempt(formData.username, formData.password);
		},
	});

	function handleLogInAttempt(username: string, password: string) {
		setLoggingIn(true);
		auth.Login(username, password).then((result) => {
			if (result !== LoginResultEnum.Success) {
				console.error("Login failed");
				setLoggingIn(false);
				setFailed(true);
			} else {
				console.info("Logged in successfully", auth.GetJwtData());
				setCurrentView(JiraView);
			}
		});
	}

	const usernameField = useField("username", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.username.length === 0 && "Username missing!!";
			},
		],
		fieldsToValidateOnChange: [],
	});

	const passwordField = useField("password", form, {
		defaultValue: "",
		validations: [
			(formData) => {
				return formData.password.length === 0 && "Password missing!!";
			},
		],
		fieldsToValidateOnChange: [],
	});

	return (
		<section className="log-in-screen">
			<form className="log-in-form" onSubmit={form.onSubmit}>
				<fieldset>
					<Field
						{...usernameField}
						formSubmitted={form.submitted}
						label="Email Address"
						type="email"
						disabled={loggingIn}
					/>
					<Field
						{...passwordField}
						formSubmitted={form.submitted}
						label="Password"
						type="password"
						disabled={loggingIn}
					/>

					<input className="log-in-button" type="submit" value="Log In" disabled={loggingIn} />

					<div className="failed-log-in-label">{failed ? "Log In Failed" : ""}</div>
				</fieldset>
			</form>
		</section>
	);
}
