import React, { useState, useContext } from "react";
import "./App.css";
import { ViewManager } from "./Components/ViewManager";
import { ViewContextProvider } from "./Context/ViewContextProvider";

export function App() {
	//const loggedIn = false;

	return (
		<div className="App">
			<header className="App-header">
				<h1>Chronometric</h1>
			</header>
			<main>
				<ViewContextProvider>
					<ViewManager />
				</ViewContextProvider>
			</main>
		</div>
	);
}
