import React, { useState, useContext } from "react";
import { Heading } from "../Library/Heading";
import { CenteredLayout } from "../Library/Layout/CenteredLayout";
import { LogInScreen } from "../LogInScreen/LogInScreen";

export function VerificationEmailView() {
	return (
		<>
			<Heading content={"Thanks for signing up, {firstname}!"} />
			<CenteredLayout>
				<LogInScreen />
			</CenteredLayout>
		</>
	);
}
