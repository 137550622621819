import axios from "axios";
import { IListAvailableIntegrationResponseModel } from "./Models/IListAvailableIntegrationResponseModel";
import { auth } from "../AuthManager/AuthManager";

export class AvailableIntegrationsAPI {
	public static async GetAvailableIntegrations(): Promise<IListAvailableIntegrationResponseModel> {
		return (await axios.get<IListAvailableIntegrationResponseModel>("/api/availableintegration", {
			headers: {
				Authorization: `Bearer ${auth.Token}`,
			},
		})).data;
	}
}
